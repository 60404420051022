var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<label class=\"control-label text-bold col-xs-4\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"receipt_example",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":1,"column":48},"end":{"line":1,"column":86}}}))
    + ":</label>\n<div class=\"col-xs-8\">\n    <div class=\"row\">\n        <div class=\"loader-wrapper\" data-name=\"receipt-example\"><div class=\"loader\"></div></div>\n        <div class=\"col-xs-6\" data-ui=\"print-button\">\n            <button class=\"btn designer-box rounded\" data-ui=\"print\">\n                <i class=\"fas fa-print\"></i> "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"print",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":7,"column":45},"end":{"line":7,"column":73}}}))
    + "\n            </button>\n        </div>\n\n        <p data-ui=\"no-printer-text\" style=\"font-size:14px\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"no_receipt_printer_connected",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":11,"column":60},"end":{"line":11,"column":111}}}))
    + "</p>\n\n    </div>\n</div>\n";
},"useData":true});