var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"lastSyncDate") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":10,"column":19}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    return "                <i class=\"far fa-stop-circle text-warning\"></i> "
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"needs_synchronization_comma_last_on_{last_sync_date}",depth0,{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":7,"column":64},"end":{"line":7,"column":142}}}))
    + "\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "                <i class=\"far fa-stop-circle text-warning\"></i> "
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"needs_synchronization",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":9,"column":64},"end":{"line":9,"column":108}}}))
    + "\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "            <i class=\"far fa-check-circle text-success\"></i> "
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"fully_synchronized_on_{last_sync_date}",depth0,{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":12,"column":61},"end":{"line":12,"column":125}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<label class=\"control-label text-bold col-xs-4\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"product_cache",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":1,"column":48},"end":{"line":1,"column":84}}}))
    + ":</label>\n<div class=\"col-xs-8\" style=\"font-size: 15px;\">\n    <div class=\"loader-wrapper\" data-name=\"product-cache\"><div class=\"loader\"></div></div>\n    <p class=\"mb-1\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"needsSynchronization") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":13,"column":15}}})) != null ? stack1 : "")
    + "    </p>\n    <button class=\"btn btn-block designer-box rounded\" data-action=\"synchronize\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"synchronize_now",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":15,"column":81},"end":{"line":15,"column":119}}}))
    + "</button>\n</div>\n";
},"useData":true});