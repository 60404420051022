var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"dialog--header\">\n    <h2 data-ui=\"title\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"technicial_mode",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":2,"column":24},"end":{"line":2,"column":62}}}))
    + "</h2>\n    <button class=\"dialog--close btn btn-danger\" data-action=\"close\"><i class=\"fa fa-times\"></i></button>\n</div>\n<div class=\"dialog--content\">\n    <div class=\"alert alert-danger\">\n        "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"any_changes_here_should_be_only_done_by_qualified_storekeeper_technician_actions_done_here_can_be_harmfull_to_the_pos_device",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":7,"column":155}}}))
    + "\n\n        <br/>\n        <div class=\"pull-right\">\n            <button class=\"btn btn-default btn-regular\" data-ui=\"accept\">\n                "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"i_m_aware_and_i_wish_to_proceed",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":12,"column":16},"end":{"line":12,"column":70}}}))
    + "\n            </button>\n        </div>\n        <br style=\"clear: both\"/>\n    </div>\n</div>\n";
},"useData":true});