var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"dialog--header\">\n    <h2 data-ui=\"title\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"technicial_mode",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":2,"column":24},"end":{"line":2,"column":62}}}))
    + "</h2>\n    <button class=\"dialog--close btn btn-danger\" data-action=\"close\"><i class=\"fa fa-times\"></i></button>\n</div>\n<div class=\"dialog--content\">\n    <div class=\"alert alert-danger\">\n        "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"reserting_the_pos_to_factory_defaults",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":7,"column":68}}}))
    + "\n        <br/>\n        <div class=\"pull-right\">\n            <button class=\"btn btn-default btn-regular\" data-ui=\"reset-pos\">\n                "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"reset_this_pos",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":11,"column":16},"end":{"line":11,"column":53}}}))
    + "\n            </button>\n        </div>\n        <br style=\"clear: both\"/>\n    </div>\n</div>\n";
},"useData":true});